import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FadeIn from "../components/FadeIn"
import Balance from "../images/balance.png"

const Werkwijze = () => (
  <Layout>
    <SEO title="Werkwijze" />
    <h1>Werkwijze</h1>
    <p>
      Wanneer u contact met mij hebt opgenomen, maken we een afspraak voor een
      intakegesprek bij u thuis waarin uw wensen worden besproken en de
      mogelijkheden die Balans in hoofd en huis u kan bieden. Het huisbezoek
      heeft als voordeel dat er gelijk zicht komt op alle levensgebieden (wonen,
      werken, activiteit en vrije tijd, leren, familie en sociale contacten,
      financiën). Aan de hand van het gesprek maken we een plan van aanpak en
      worden de doelen beschreven waaraan gewerkt gaat worden.
    </p>

    <div>
      <FadeIn src={Balance} alt="Balans" />

      <p>
        Wanneer u mij particulier inhuurt, zijn de tarieven in overleg en
        afhankelijk van de hulpvraag en het tijdsbestek.
      </p>

      <p>
        Indien u een WMO indicatie heeft (Wet Maatschappelijke Ondersteuning)
        kan de begeleiding via zorg in natura (ZIN) plaats vinden. U betaalt dan
        alleen een eigen bijdrage via het CAK (Centraal Administratie Kantoor).
        Dit is een maandelijks bedrag van €20,60. Het kan ook minder zijn.
        Gemeenten mogen de eigen bijdrage van 20,60 namelijk verlagen.
      </p>

      <p>
        Balans in hoofd en huis heeft WMO-contracten met de gemeenten Lochem,
        Zutphen, Apeldoorn, Voorst en Brummen.
      </p>
    </div>

    <Link to="/contact" className="btn btn-danger mt-4" role="button">
      Neem contact op
    </Link>
  </Layout>
)

export default Werkwijze
